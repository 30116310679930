import { createContext, useContext } from "react";

export const ModalContext = createContext<{
  close?: (...args: unknown[]) => unknown;
}>({ close: undefined });

export function useCustomModal() {
  const { close } = useContext(ModalContext);

  return {
    close,
  };
}
