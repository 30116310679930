enum variants {
  Hidden = "hidden",
  Show = "show",
}

const DURATION = 0.2;

export const contentVariantOptions = {
  [variants.Hidden]: {
    scaleX: 0.98,
    scaleY: 0.98,
    y: 20,
    opacity: 0,
    transition: {
      duration: DURATION,
    },
  },
  [variants.Show]: {
    y: 0,
    scaleX: 1,
    scaleY: 1,
    opacity: 1,
    transition: {
      duration: DURATION,
    },
  },
};

export const modalContentMotion = {
  variants: contentVariantOptions,
  initial: variants.Hidden,
  animate: variants.Show,
  exit: variants.Hidden,
};

export const backdropVariantOptions = {
  [variants.Hidden]: {
    transition: {
      duration: DURATION,
    },
    opacity: 0,
  },
  [variants.Show]: {
    transition: {
      duration: DURATION,
    },
    opacity: 1,
  },
};

export const modalBackdropMotion = {
  variants: backdropVariantOptions,
  initial: variants.Hidden,
  animate: variants.Show,
  exit: variants.Hidden,
};
