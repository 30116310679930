import omit from "lodash/omit";
import { NextRouter } from "next/router";

/**
 * Helper function to remove one or many query parameters from the URL,
 * without adding to browser history or causing a re-render.
 */
export function removeQueryParams(router: NextRouter, params: string[]) {
  void router.replace(
    {
      pathname: router.pathname,
      query: omit(router.query, params),
    },
    undefined,
    { shallow: true }
  );
}

/**
 * Helper function to add one or many query parameters from the URL,
 * without adding to browser history.
 */
export const addQueryParams = (
  router: NextRouter,
  params: Record<string, string>
) => {
  const noChange = Object.entries(params).every(([key, value]) => {
    return router.query[key] === value;
  });

  // Prevent needless re-renders
  if (noChange) return;

  void router.replace(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
        ...params,
      },
    },
    undefined,
    { shallow: true }
  );
};
