import useSWR from "swr";
import { EventsQuery, OrderByDirection } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import { sdk } from "~lib";
import { today } from "~lib/helpers/dates";

interface EventsServiceProps {
  search?: string;
  tourId?: string;
  isEnabled?: boolean;
  hiddenFromPublic?: boolean;
  includeFeaturedEvents?: boolean;
}

export type EventsList = EventsQuery["events"]["edges"][number]["node"][];
export interface EventsServiceReturn {
  error: Error;
  isLoading: boolean;
  events: EventsList;
  featuredEvents: EventsList;
}

const swrConfig = {
  shouldRetryOnError: false,
};

const getEvents = async (
  _: string,
  orgId: string,
  search?: string,
  seasonId?: string,
  hiddenFromPublic = true
) => {
  const where = {
    startDate: today,
    ...(search && { q: search }),
    seasonId,
  };

  return sdk({ orgId })
    .events({
      where,
      orderBy: {
        startDate: OrderByDirection.Asc,
      },
    })
    .then((res) =>
      res.events?.edges
        ?.map(({ node }) => node)
        .filter((event) => (hiddenFromPublic ? !event.hiddenFromPublic : true))
    );
};

export const useEventsService = ({
  search,
  tourId,
  hiddenFromPublic,
  isEnabled = true,
  includeFeaturedEvents = false,
}: EventsServiceProps = {}): EventsServiceReturn => {
  const { organization } = useOrganization();

  const { data: events, error: eventsError } = useSWR(
    isEnabled
      ? ["events", organization?.id, search, tourId, hiddenFromPublic]
      : null,
    getEvents,
    swrConfig
  );

  const featuredEvents = events?.filter((event) => event.isFeatured) || [];

  const error = isEnabled && eventsError;

  const isLoading = isEnabled ? !events && !error : false;

  return {
    error,
    isLoading,
    events,
    featuredEvents: includeFeaturedEvents ? featuredEvents : undefined,
  };
};
