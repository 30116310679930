import styled from "styled-components";
import { Icon } from "~components/common/Icon";

const HoverIcon = styled(Icon).attrs((p) => ({
  borderRadius: "sm",
  p: "1/2",
  // className: cn("hover-child", p.className),
}))`
  cursor: pointer;
  transition: background-color 0.12s ease-out;

  &:active,
  &.active {
    transition-duration: 0;
    background-color: ${(p) => p.theme.colors.N100};
  }

  // Prevents hover while in active state.
  &:not(:active, .active):hover {
  }
`;

export default HoverIcon;
